import { useState, useEffect } from "react";
import { Flex, Image, Text, useTheme, Divider, Heading } from "@aws-amplify/ui-react";
// import { Heading } from '@aws-amplify/ui-react';
import { SocialSignUpButton } from "./SocialSignUpButton";

import logo192 from "../assets/logo192.png";

export const LoginFormFields = {
	signIn: {
		username: {
			dialCode: '+61'
		},
	},
	signUp: {
		phone_number: {
			dialCode: '+61'
		},
	}
}

function Header() {
	const headerText = 'Welcome to ScanseQR'
	const [charIndex, setCharIndex] = useState(1)

	useEffect(() => {
		if (charIndex < headerText.length) {
			setTimeout(() => setCharIndex(charIndex + 1), 100)
		}
	}, [charIndex])

	return (
		<Flex justifyContent="center" alignItems={'center'} direction={'column'}>
			<Image
				alt="logo"
				src={logo192}
			/>
			<a href="https://www.scanseqr.com" target="_blank" rel="noreferrer"><Text>What is this?</Text></a>
			<Flex justifyContent="center" alignItems={'center'} direction={'column'} width={'100%'} backgroundColor={'#ff9903'}>
				<Heading level={3} style={{
					marginTop: 5,
					fontFamily: 'Courier New',
					color: '#FFF',
				}} >
					{headerText.slice(0, charIndex)}
				</Heading>
			</Flex>
		</Flex>
	);
}

function Footer() {
	const { tokens } = useTheme();

	return (
		<Flex justifyContent="center" padding={tokens.space.small}>
			<Text>&copy; ScanseQR All Rights Reserved</Text>
		</Flex>
	);
}

export const AuthComponents = (inviteData, providers) => ({
	Header,
	Footer,
	SignUp: {
		Header() {
			return (
				<div>
					{
						providers.map(provider => (
							<SocialSignUpButton
								key={provider}
								inviteData={inviteData}
								buttonText={`Sign Up With ${provider}`}
								provider={provider}
							/>
						))
					}
					<Divider label="OR" />
				</div>
			);
		}
	},
	SignIn: {
		Header() {
			return (
				<div>
					{
						providers.map(provider => (
							<SocialSignUpButton
								key={provider}
								inviteData={inviteData}
								buttonText={`Sign In With ${provider}`}
								provider={provider}
							/>
						))
					}
					<Divider label="OR" />
				</div>
			);
		}
	}
});
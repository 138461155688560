import React, { useCallback } from 'react';
// LIBRARIES
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// MATERIAL
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

// ICONS
import MoreVertIcon from '@mui/icons-material/MoreVert';

// CONTEXT
// import { useLocationContext } from "./LocationContext";

const menuItems = ['Account', 'Invite', 'Refresh Location', 'Logout'];

function AppToolbar() {
  const { signOut } = useAuthenticator();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  // const locationContext = useLocationContext()

  const scanseqrLogo = require('../assets/logo192.png');

  const handleOpenUserMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseUserMenu = async (menuItem) => {
    setAnchorElMenu(null);
    switch (menuItem) {
      case 'Logout':
        signOut();
        break;
      case 'Account':
        navigate('/account');
        break;
      case 'Invite':
        navigate('/invite');
        break;
      case 'Refresh Location':
        requestGeo();
        break;
      default:
        break;
    }
  };

  const geo = navigator.geolocation;

  const onError = useCallback(
    (error) => {
      console.log('LOCATION ERROR', error);
      enqueueSnackbar('GeoLocation not available.', { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  const onSuccess = useCallback(
    (data) => {
      console.log('LOCATION', data.coords);
      enqueueSnackbar(
        `GeoLocation available.(${data.coords.latitude.toFixed(
          5
        )},${data.coords.longitude.toFixed(5)})`,
        { variant: 'success' }
      );
    },
    [enqueueSnackbar]
  );

  const requestGeo = () => {
    geo.getCurrentPosition(onSuccess, onError, {
      enableHighAccuracy: false,
      maximumAge: 15000,
      timeout: 3000,
    });
    console.log('REQUESTING NAV SERVICES');
  };

  const goHome = () => {
    navigate('/');
  };

  return (
    <AppBar position='static' color='secondary'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Avatar
            variant='square'
            sx={{ display: 'flex', mr: 1 }}
            alt='S'
            onClick={goHome}
            src={scanseqrLogo} // TODO Get a smaller icon
          />
          <Typography
            variant='h5'
            noWrap
            component='a'
            href='/'
            sx={{
              mr: 2,
              display: 'flex',
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ScanseQR
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <MoreVertIcon color='primary' />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElMenu)}
              onClose={handleCloseUserMenu}
            >
              {menuItems.map((menuItem) => (
                <MenuItem key={menuItem} onClick={() => handleCloseUserMenu(menuItem)}>
                  <Typography textAlign='center'>{menuItem}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppToolbar;

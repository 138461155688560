// Haversine_formula https://en.wikipedia.org/wiki/Haversine_formula
export function detectDistance(deviceLocation, currentLocation, allowedRange) {
  if (deviceLocation == null || !deviceLocation.lat || !deviceLocation.lng) return false;
  if (currentLocation == null || !currentLocation.lat || !currentLocation.lng) return false;

  const { lat: lat1, lng: lng1 } = deviceLocation;
  const { lat: lat2, lng: lng2 } = currentLocation;

  const degToRad = (x) => (x * Math.PI) / 180;
  const R = 6371;
  const halfDLat = degToRad(lat2 - lat1) / 2;
  const halfDlng = degToRad(lng2 - lng1) / 2;
  const a =
    Math.sin(halfDLat) * Math.sin(halfDLat) +
    Math.cos(degToRad(lat1)) *
      Math.cos(degToRad(lat2)) *
      Math.sin(halfDlng) *
      Math.sin(halfDlng);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const km = R * c;
  const meters = km * 1000;
  if (allowedRange >= meters) return true;
  return false;
}

export function inRange(device, userLocation) {
    return device.distanceEnabled
    ? device.lat && device.lng
      ? detectDistance(
          { lat: device.lat, lng: device.lng },
          userLocation,
          device.allowedDistance
        )
      : false
    : true;
}

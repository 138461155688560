import { useCallback, useContext, useEffect, useState, createContext } from 'react'
import { useSnackbar } from 'notistack';

export const LocationContext = createContext({})

export function useLocationContext() { return useContext(LocationContext) }

const LocationContextProvider = props => {
    // const [ userLocation, setUserLocation ] = useState(null)
    // const { enqueueSnackbar } = useSnackbar();
    // const geo = navigator.geolocation;

    // const onError = useCallback((error) => {
    //     console.log('LOCATION ERROR', error)
    //     enqueueSnackbar(`LOCATION - ${error.message}`, { variant: 'error' })
    // },[enqueueSnackbar]);

    // useEffect(() => {
    //     if (!geo) {
    //         enqueueSnackbar('Geolocation is not supported', { variant: 'error' })
    //         return;
    //     }
    //     const watcher = geo.watchPosition(onChange, onError, {enableHighAccuracy: false, maximumAge: 15000, timeout: 3000});
    //     return () => geo.clearWatch(watcher);
    // }, [enqueueSnackbar, geo, onError]);

    // const onChange = ({coords}) => {
    //     console.log(`[${new Date().toISOString().substring(11, 23)}] - LOCATION UPDATED: `, coords)
    //     setUserLocation({
    //         lat: parseFloat(coords.latitude.toFixed(6)),
    //         lng: parseFloat(coords.longitude.toFixed(6)),
    //     });
    // };

    // const getLocation = () => {
    //     console.log(`[${new Date().toISOString().substring(11, 23)}] - GET LOCATION`)
        
    //     geo.getCurrentPosition((position) => {
    //         // console.log(`[${new Date().toISOString().substring(11, 23)}] - POS: `, position.coords)
    //         setUserLocation({
    //             lat: parseFloat(position.coords.latitude.toFixed(6)),
    //             lng: parseFloat(position.coords.longitude.toFixed(6)),
    //         })
    //     }, onError);
    // };

    // return (
    //     <LocationContext.Provider value={{getLocation, userLocation}}>
    //         { props.children }
    //     </LocationContext.Provider>
    // )
}

export default LocationContextProvider
import { Auth } from 'aws-amplify';

const awsExports = {
  Auth: {

    authenticationFlowType: process.env.REACT_APP_REGION === "local" ? "USER_PASSWORD_AUTH" : null,
    endpoint: process.env.REACT_APP_REGION === "local" ? "http://localhost:9229" : null,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    aws_cognito_social_providers: process.env.REACT_APP_SOCIAL_PROVIDERS !== undefined
      ? process.env.REACT_APP_SOCIAL_PROVIDERS.split(",").filter(v => v !== "")
      : [
        "GOOGLE",
        "FACEBOOK"
      ],
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
      responseType: "code"
     },
     federationTarget: "COGNITO_USER_POOLS"
  },
  API: {
    endpoints: [
      {
        name: 'Scanseqr',
        endpoint: process.env.REACT_APP_ENDPOINT,
        region: process.env.REACT_APP_REGION,
        custom_header: async () => {
          return { IdToken: `${(await Auth.currentSession().catch(() => 'null'))?.idToken?.jwtToken}`};
        }
      }
    ]
  }
};

export default awsExports;



import '@aws-amplify/ui-react/styles.css';
// LIBRARIES
import { BrowserRouter as Router } from 'react-router-dom';
import { Hub } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
// COMPONENTS
import MyRoutes from './Routes';
// FUNCTIONS
import { listener } from './functions/listner';

import theme from './styles/materialTheme';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to Infinity, you have to invalidate the query to refetch
      staleTime: process.env.NODE_ENV === 'development' ? Infinity : 0,
      refetchOnWindowFocus: process.env.NODE_ENV === 'development' ? false : true,
    },
  },
});

// Listen for AWS Amplify Auth Events
Hub.listen('auth', listener);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <SnackbarProvider maxSnack={3} preventDuplicate>
            <MyRoutes />
          </SnackbarProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;

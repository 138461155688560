import { useState, useEffect } from 'react';


export const useCamera = () => {
  const [error, setError] = useState('Prompt');

  useEffect(() => {
    let stream;

    navigator.mediaDevices.getUserMedia({ video: true }).then((videoStream) => {
        stream = videoStream;
        // Permission granted, but we don't need the stream now
        stream.getTracks().forEach((track) => track.stop());
        console.log('Camera permission granted');
        setError(null);
      })
      .catch((err) => {
        // Permission denied
        console.error('Camera permission denied:', err);
        setError('denied');
      });

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  // useEffect(() => {

  //   const askForCameraPermission = async () => {
  //     try {
  //       const permission = await navigator.permissions.query({ name: 'camera' });
  //       setCameraPermission(permission.state);
  //       if (permission.state === 'granted') {
  //         return;
  //       }
  //     } catch (error) {
  //       console.error('Error requesting camera permission:', error);
  //     }
  //   };

  //     // Firefox -> TypeError: 'name' member of PermissionDescriptor 'camera' is not a valid value for enumeration PermissionName.
  //   if (!navigator.userAgent.includes('Firefox')) {
  //     askForCameraPermission();
  //   } else {
  //     setCameraPermission('prompt');
  //   }

  //   const intervalRef = window.setInterval(async () => {
  //     askForCameraPermission();
  //     const permission = await navigator.permissions.query({ name: 'camera' });
  //     setCameraPermission(permission.state);
  //   }, 4000);

  //   return () => {
  //     window.clearInterval(intervalRef);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (cameraPermission === 'initial') {
  //     return;
  //   }
  //   if (cameraPermission === 'prompt') {
  //     // Ask for permission to use the camera
  //     navigator.mediaDevices
  //       .getUserMedia({ video: true })
  //       .then((stream) => {
  //         // Permission granted, but we don't need the stream now
  //         stream.getTracks().forEach((track) => track.stop());
  //         console.log('Camera permission granted');
  //       })
  //       .catch((err) => {
  //         // Permission denied
  //         console.error('Camera permission denied:', err);
  //       });
  //   } else if (cameraPermission === 'granted') {
  //     return;
  //   } else {
  //     enqueueSnackbar('Permission to use the camera is required.', {
  //       variant: 'error',
  //       // preventDuplicate: true,
  //     });
  //   }
  // }, [cameraPermission, enqueueSnackbar]);

  return { cameraError: error };
};

//@format
import React from 'react';
import { Amplify } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import awsExports from './aws-exports';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import './styles/style.css';
import ErrorBoundary from './components/ErrorBoundary';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import p from '../package.json';

Sentry.init({
  dsn: 'https://d3b8208ff59d405db99e3ba68af6dd5d@sentry.refactor.com.au/24',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: `${p.name}@${p.version}`,
  enabled: process.env.NODE_ENV !== 'development',
});

Amplify.configure(awsExports);

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_IOT_ENDPOINT,
  })
);

const root = ReactDOM.createRoot(document.getElementById('root'));
// react-qr-reader has MAJOR issues with StrictMode - remove while testing
root.render(
  // <React.StrictMode>
    <ErrorBoundary fallback='There was an Error'>
      <AmplifyProvider>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </AmplifyProvider>
    </ErrorBoundary>
// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

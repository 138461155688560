import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import FilterList from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { RefreshRounded } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function SearchBar({
    searchText,
  handleText,
  onRefresh,
  handleFilters,
  filters,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper
      component='form'
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 1,
        marginBottom: 2,
        width: '100%',
      }}
    >
      <div>
        <IconButton
                    id="filter-button"
                    aria-controls={menuOpen ? 'filter-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    onClick={handleMenu}
                    sx={{ p: '10px' }}
                    >
                    <FilterList />
                </IconButton>
        <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
          <SearchIcon />
        </IconButton>
        <Menu
          id='filter-menu'
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'filter-button',
          }}
        >
          <div style={{ padding: 4 }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend' color={'secondary'}>
                Include:
              </FormLabel>
              <FormGroup aria-label='position'>
                {filters.map((f, i) => (
                  <FormControlLabel
                    key={`filter-${i}`}
                    control={
                      <Checkbox
                        id={f.id}
                        checked={f.value}
                        onChange={handleFilters}
                        color={'secondary'}
                        disabled={f.disabled || false}
                      />
                    }
                    label={f.label}
                    labelPlacement='end'
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </Menu>
      </div>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder='Search'
        value={searchText}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => handleText(e.target.value)}
      />

      <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
      <IconButton onClick={onRefresh} color='primary' sx={{ p: '10px' }}>
        <RefreshRounded />
      </IconButton>
    </Paper>
  );
}

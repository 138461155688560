import { useCallback, useState } from 'react';
import { API } from 'aws-amplify'; // Auth
import { useQuery } from '@tanstack/react-query';
import { inRange } from '../functions/detectDistance';

const defaultDeviceFilters = [
  // {
  //   id: 'allSevices',
  //   value: true,
  //   label: 'All',
  // },
  {
    id: 'offlineDevices',
    value: true,
    label: 'Offline',
  },
  {
    id: 'outOfRangeDevices',
    value: true,
    label: 'Out of Range',
  },
];

const fetchDevices = async userLocation => {
  const result = await API.get('Scanseqr', 'devices/get-users-devices');
  console.log('RESULT: ', result);
//   if (!result.ok) {
//     throw new Error('Failed to fetch devices', result);
//   }
const deviceWithInRange = result.devices?.map((device) => {
    return {
      ...device,
      inRange: inRange(device, userLocation),
    };
  })
  console.log('deviceWithInRange: ', deviceWithInRange);
  localStorage.setItem('SQR_DEVICES', JSON.stringify(deviceWithInRange));
  return deviceWithInRange || [];
};

export const useDevicesQuery = (userLocation) => {
  const [deviceFilter, setDeviceFilter] = useState(defaultDeviceFilters);

//   console.log(userLocation);

  const handleDeviceFilters = (event) => {
    setDeviceFilter((prev) =>
    prev.map((item) => {
        if (item.id === event.target.id) item.value = !item.value;
        return item;
      })
    );
  };

  const filterDevices = useCallback(
    (devices) => {
        if (!deviceFilter[0].value && !deviceFilter[1].value) return devices.filter((device) => (device.status && device.inRange));
        if(deviceFilter[0].value && deviceFilter[1].value) return devices;
        if(deviceFilter[0].value) return devices.filter((device) => (device.inRange));
        if(deviceFilter[1].value) return devices.filter((device) => (device.status));
        return devices;
    },
    [deviceFilter]
  );

  const {
    data: devices, // = []
    isLoading,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['devices'],
    queryFn: () => fetchDevices(userLocation),
    placeholderData: JSON.parse(localStorage.getItem('SQR_DEVICES')) || [],
    refetchOnWindowFocus: false,
    retry: 2,
    select: filterDevices,
    enabled: !!(userLocation.lat && userLocation.lng),
  });

    // const { data: rawDevices, status } = useQuery({
  //   queryKey: ['devices'],
  //   queryFn: () => fetchDevices(),
  //   // The query will not execute until follow condition is met
  //   //   enabled: !!user,
  // });

  return {
    devices,
    isLoading,
    isFetching,
    error: error,
    handleDeviceFilters,
    deviceFilter,
  };
};

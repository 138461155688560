// import { useState } from 'react';
// LIBRARIES
import { useNavigate } from 'react-router-dom';

// MATERIAL
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// ICONS
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TungstenIcon from '@mui/icons-material/Tungsten'; // Light On
import FlashOnIcon from '@mui/icons-material/FlashOn'; // Power On

// import { inRange } from '../functions/detectDistance';


export default function DoorCard(props) {
    const { device } = props;
    // const device.inRange = inRange(device, userLocation);

  const navigate = useNavigate();

  // console.log(props);

  const unlock = () => {
    navigate({pathname: `/trigger/${device.uuid.substring(0, 8)}`, search: `?q=${device.deviceId}`});
  };


  const iconStyle = { width: 70, height: 70, color: '#FFFFFF' };

  const deviceIcon = () => {
    switch (parseInt(device.deviceTypeId)) {
      case 2: // DOOR
        return <MeetingRoomIcon sx={iconStyle} />;
      case 3: // LIGHT
        return <TungstenIcon sx={iconStyle} />;
      case 4: // POWER
        return <FlashOnIcon sx={iconStyle} />;
      default:
        return <MeetingRoomIcon sx={iconStyle} />;
    }
  };
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          height: 90,
          backgroundImage:
          device.inRange && device.status
              ? `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`
              : `linear-gradient(to bottom right,#FFF,#AAA,#FFF)`,
          // If browser does not support linear-gradient
          backgroundColor: device.inRange ? '#ff9900' : '#AAA',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {deviceIcon()}
      </div>
      <CardContent>
        <Typography variant='body1' color='text.secondary' component='div'>
          {device.deviceName}
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant='caption' color='text.secondary'>
          {device.inRange ? (device.status ? device.description : 'Offline') : 'Out of range'}
        </Typography>
      </CardContent>
      {device.disabled && (
        <Typography variant='caption' color='text.secondary' align='center'>
          {device.status ? 'Device offline' : 'Out of range'}
        </Typography>
      )}
      <Button
        variant='contained'
        sx={
          !device.disabled
            ? { backgroundImage: `radial-gradient(#030405 5%, #182028 15%, #1f2833 60%)` }
            : {}
        }
        color='secondary'
        onClick={unlock}
        fullWidth
        disabled={device.disabled || !device.inRange || !device.status} //  || !status <= Not yet as ALL are offline
      >
        OPEN
      </Button>
    </Card>
  );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MATERIAL
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

// ICONS
import Dashboard from '@mui/icons-material/Dashboard';
// import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

// CONTEXT
// import { useSnackbar } from 'notistack';
import p from '../../package.json';

export default function BottomNavigator() {
  const [value, setValue] = useState('dashboard');
  // const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // function to detect if user is on mobile device for both android and ios
  // const isMobile = () => {
  //   const userAgent = navigator.userAgent.toLowerCase();
  //   return /iphone|ipad|ipod|android/i.test(userAgent);
  // };

  const handleChange = (event, newValue) => {
    if (newValue === 'scan') {
      // const isMobile = navigator.userAgentData.mobile; <= this is not supported by safari
        navigate('/scan');
        setValue(newValue);
    } else {
      navigate('/');
      setValue(newValue);
    }
  };

  return (
    <>
      <BottomNavigation
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
        value={value}
        onChange={handleChange}
        showLabels={true}
      >
        <BottomNavigationAction label='Dashboard' value='dashboard' icon={<Dashboard />} />
        {/* <BottomNavigationAction
            label="SomeFeature"
            value="feature"
            disabled={true}
            icon={<DeviceUnknownIcon />}
          /> */}
        <BottomNavigationAction label='Scan' value='scan' icon={<QrCodeScannerIcon />} />
        
      </BottomNavigation>
      <div style={{position: 'absolute', bottom: 0, right: 0, color: '#aaa', fontSize: '8px', marginRight: '10px'}}>Version {p.version}</div>
    </>
  );
}

import { useEffect } from 'react';
// LIBRARIES
import { Auth } from 'aws-amplify';
import { Authenticator, Flex, ThemeProvider, useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
// ASSETS
import qrCodeBackground from '../assets/qrCode.png';
// THEME
import amplifyTheme from '../styles/amplifyTheme';
// FUNCTIONS
// COMPONENTS
import { AuthComponents, LoginFormFields } from '../components/AuthenticatorComponents';

import amplifyExports from '../aws-exports';


export default function Login(props) {
  const providers = amplifyExports.Auth.aws_cognito_social_providers.map(
    (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase()
  );
  const { initialState, invite } = props;
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

    // If the user specifies a from path, use that instead of the default when logging in
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const services = {
    async handleSignIn(formData) {
      return await Auth.signIn(formData).then(async (user) => {
        return user;
      });
    },
  };

  return (
    <ThemeProvider theme={amplifyTheme}>
      <Flex
        justifyContent='center'
        style={{
          backgroundImage: `url(${qrCodeBackground})`,
          backgroundRepeat: 'repeat',
          backgroundColor: 'transparent',
          height: '100vh',
        }}
      >
        <Authenticator
          initialState={initialState}
          formFields={LoginFormFields}
          services={services}
          loginMechanisms={['email']}
          // Removed as is does not provide way to parse state
          signUpAttributes={['phone_number', 'email']}
          components={AuthComponents(invite, providers)}
        />
      </Flex>
    </ThemeProvider>
  );
}

import { API, Auth } from 'aws-amplify';

export const applyInviteApi = async (postBody) => {
    const result = await API.post('Scanseqr', 'authentication/action-invite', postBody);
    return result;
};

export const checkInviteApi = async (encodedData) => {
    const result = await API.post('Scanseqr', 'authentication/check-invite', {
        body: {
            invite: encodedData,
        },
    });
    return result;
};

export const ApplyInvite = async (invite) => {
    await API.post('Scanseqr', 'authentication/action-invite', {
        body: {
        invite: invite,
        },
    })
    .then(async () => {
        // Fetch a new token from cognito
        await Auth.currentAuthenticatedUser({ bypassCache: true });
    })
    .catch((err) => {
        throw err;
    });
};

export const getUsersInvitesApi = async () => {
    const result = await API.get('Scanseqr', 'users/get-user-invites');
    return result.invites;
}

import React from 'react';
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

// COMPONENTS
import AppToolbar from './components/AppToolbar';
import BottomNavigator from './components/BottomNavigator';
import LocationContextProvider from './components/LocationContext';

// PAGES
import Dashboard from './pages/Dashboard';
import Account from './pages/Account';
import Scan from './pages/Scan';
import AccessToken from './pages/AccessToken';
import Login from './pages/Login';
import Trigger from './pages/Trigger';
import Invite from './pages/Invite';

// FUNCTIONS
import { padding } from './functions/styleHelpers';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  if (route !== 'authenticated') {
    const deviceId = location.pathname.split('/')[2];
    const possibleKey = localStorage.getItem(deviceId) || null;
    console.log(location, deviceId, possibleKey);
    
    if (possibleKey) {
      return <Navigate to={`/key/${encodeURIComponent(possibleKey)}`} />;
    }
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  return (
        <div>
            <AppToolbar />
            <div style={padding(7, 7, 70, 7)}>
              <Outlet />
            </div>
            <BottomNavigator />
        </div>
      )
}

const deviceActionUrls = [
  { url: '/ta/:id', action: 'AuthToggle' },
  { url: '/th/:id', action: 'Check in' },
  { url: '/to/:id', action: 'On' },
  { url: '/tb/:id', action: 'Check out' },
  { url: '/tt/:id', action: 'Toggle' },
  { url: '/tp/:id', action: 'Pulse' },
  { url: '/tm/:id', action: 'Multi Channel' },
  { url: '/ks/:id', action: 'Key Store' },
];

const MyRoutes = () => {
  const location = useLocation();
  // const { route } = useAuthenticator((context) => [context.route]);
  return (
    <Routes>
      <Route element={<RequireAuth />}>
          <Route index element={<Dashboard />} />
          <Route path='/dashboard' element={<Navigate to='/' />} />
          <Route path='/scan' element={<Scan />} />
          <Route path='/account' element={<Account />} />
          <Route path='/trigger/:id' element={<Trigger method={'Dashboard Button'} />} />
          <Route path='/im/:id' element={<Invite />} />
          <Route path='/is/:id' element={<Invite />} />
          <Route path='/invite' element={<Invite />} />
          {deviceActionUrls.map((el) => (
            <Route key={el.url} path={el.url} element={<Trigger method={'Scanned QR'} />} />
          ))}
          
        </Route>
        <Route
          path='/key/:encodedData'
          element={
              <AccessToken />
          }
        />
        <Route path='/login' element={<Login initialState={'signIn'} />} />
        {/* {<Route path="/invite/:encodedData" element={route === 'authenticated' ? <Dashboard invite={location.pathname.split('/')[2]}/> : <Login initialState={"signUp"} invite={location.pathname.split('/')[2]}/>} />} */}
        <Route path="/invite/:encodedData" element={<Login initialState={"signUp"} invite={location.pathname.split('/')[2]}/>} />


        <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default MyRoutes;


const amplifyTheme = {
    name: 'Sign in Theme',
    tokens: {
        colors: {
            // Colors generated with https://mdigi.tools/color-shades/#ff9903
            brand: {
                primary: {
                    '10': {
                        value: '#ffd89f',
                    },
                    '80': {
                        value: '#ff9903', // Used for Primary Buttons
                    },
                    '90': {
                        value: '#df8500',
                    },
                    '100': {
                        value: '#9f5f00',
                    },
                },
            },
        },
        components: {
            tabs: {
                item: {
                    _focus: {
                        color: {
                            value: '#ff9903',
                        },
                    },
                    _hover: {
                        color: {
                            value: '#ff9903',
                        },
                    },
                    _active: {
                        color: {
                            value: '#ff9903',
                        },
                    },
                },
            },
        },
    },
};

export default amplifyTheme
import React, { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import useMQTT from '../hooks/useMQTT';

// CSS
import '../components/check.css';
import { Typography } from '@mui/material';

const processMqttMessage = (msg, errorTimer, setStatus) => {
  clearInterval(errorTimer.current);
  setStatus('ACTIVATED');
};

const activateDevice = async (postBody) => {
  const res = await API.post('Scanseqr', 'devices/open', postBody);
  return res;
};

const TriggerProcess = (props) => {
  const { identityId, latitude, longitude, method, deviceId, deviceUuid, command } = props;
  const [status, setStatus] = useState('CONNECTING');

  const navigate = useNavigate();

  useMQTT(`${identityId}/#`, (data) => processMqttMessage(data, errorTimer, setStatus));


  const mutation = useMutation(activateDevice, {
    onSuccess: (data) => {
      console.log(data);
      if (!data.success) {
        clearInterval(errorTimer.current);
        setStatus(data.result);
      }
    },
  });

  /*
  a = AuthToggle
  h = Check in
  o = On
  b = Check out
  t = toggle
  p = pulse
  m = multi/Custom
  */

  let errorTimer = useRef(null);

  useEffect(() => {
    if ((identityId, latitude, longitude)) {
      const postBody = {
        body: {
          deviceId,
          deviceUuid,
          command,
          lat: latitude,
          lng: longitude,
          method,
        },
      };
      mutation.mutate(postBody);

      errorTimer.current = window.setTimeout(() => {
        setStatus('Device failed to respond');
      }, 8000);
    }

    return () => {
      clearInterval(errorTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityId, latitude, longitude]);

  

  useEffect(() => {
    let returnTimer = null;
    if (status !== 'CONNECTING') {
      returnTimer = window.setTimeout(() => {
        // navigate(-1);
        navigate('/');
      }, 4000);
    }
    return () => {
      clearInterval(returnTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '100px',
        }}
      >
        {status === 'CONNECTING' && <CircularProgress size={212} />}
        {status === 'Device failed to respond' && (
          <>
            <svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 130.2 130.2'
              className='checkmark_red'
            >
              <circle
                className='checkmark__circle'
                fill='none'
                stroke='#D06079'
                cx='65.1'
                cy='65.1'
                r='62.1'
              />
              <line
                className='checkmark__check'
                fill='none'
                strokeWidth='10'
                strokeMiterlimit='10'
                x1='34.4'
                y1='37.9'
                x2='95.8'
                y2='92.3'
              />
              <line
                className='checkmark__check'
                fill='none'
                strokeWidth='10'
                strokeMiterlimit='10'
                x1='95.8'
                y1='38'
                x2='34.4'
                y2='92.2'
              />
            </svg>
          </>
        )}
        {status === 'ACTIVATED' && (
          <svg
            className='checkmark_green'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 52 52'
          >
            <circle
              className='checkmark__circle'
              cx='26'
              cy='26'
              r='25'
              fill='none'
              stroke='#7ac142'
            />
            <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
          </svg>
        )}
      </div>
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '100px',
        }}
      >
        {status}
      </Typography>
    </>
  );
};

export default TriggerProcess;

import React, { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
// import useMQTT from '../hooks/useMQTT';
import { useSnackbar } from 'notistack';

// CSS
import '../components/check.css';

import { Button, Typography, Paper, Box } from '@mui/material';

// const processMqttMessage = (msg, errorTimer, setStatus) => {
//   clearInterval(errorTimer.current);
//   setStatus('ACTIVATED');
// };

const redeemInvite = async (postBody) => {
  const res = await API.post('Scanseqr', 'authentication/redeem-invite', postBody);
  return res;
};

const checkInvite = async (postBody) => {
  const res = await API.post('Scanseqr', 'authentication/check-invite', postBody);
  return res;
};

const InviteProcess = (props) => {
  const { code } = props;
  const [status, setStatus] = useState('Checking Invite');

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const checkMutation = useMutation(checkInvite, {
    onSuccess: (data) => {
      // Invalidate and refetch
      // queryClient.invalidateQueries(['users', entity.id]);
      console.log('Checking invite:', data);
      if (data.success === true) {
        setStatus(data);
        // redeemMutation.mutate({ code: code });
      } else {
        setStatus('Invite invalid/expired');
        enqueueSnackbar(data.msg, { variant: 'error' });
      }
    },
    onError: (error) => {
      console.error('Error checking invite:', error);
      enqueueSnackbar('API Error checking Invite.', { variant: 'error' });
    },
  });

  const redeemMutation = useMutation(redeemInvite, {
    onSuccess: (data) => {
      // Invalidate and refetch
      // queryClient.invalidateQueries(['users', entity.id]);
      console.log('Redeemed invite:', data);
      if (data.success === true) {
        setStatus('Redeemed Invite');
        enqueueSnackbar(`Redeemed, waiting to be approved.`, { variant: 'success' });
      } else {
        setStatus('Invite invalid/expired');
        enqueueSnackbar('Invite invalid/expired.', { variant: 'error' });
        window.setTimeout(() => {
          navigate(-1);
        }, 4000);
      }
    },
    onError: (error) => {
      console.error('Error redeeming invite:', error);
      enqueueSnackbar('API Error redeeming Invite.', { variant: 'error' });
    },
  });

  useEffect(() => {
    if (code.length === 6) {
      const checkPostBody = {
        body: { code: code },
      };
      checkMutation.mutate(checkPostBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  // useMQTT(`${identityId}/#`, (data) => processMqttMessage(data, errorTimer, setStatus));

  // useEffect(() => {
  //   let returnTimer = null;
  //   if (status !== 'CONNECTING') {
  //     returnTimer = window.setTimeout(() => {
  //       navigate(-1);
  //     }, 4000);
  //   }
  //   return () => {
  //     clearInterval(returnTimer);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [status]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '100px',
        }}
      >
        {status === 'Checking Invite' && <CircularProgress size={212} />}
        {status === 'Invite invalid/expired' && (
          <>
            <svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 130.2 130.2'
              className='checkmark_red'
            >
              <circle
                className='checkmark__circle'
                fill='none'
                stroke='#D06079'
                cx='65.1'
                cy='65.1'
                r='62.1'
              />
              <line
                className='checkmark__check'
                fill='none'
                strokeWidth='10'
                strokeMiterlimit='10'
                x1='34.4'
                y1='37.9'
                x2='95.8'
                y2='92.3'
              />
              <line
                className='checkmark__check'
                fill='none'
                strokeWidth='10'
                strokeMiterlimit='10'
                x1='95.8'
                y1='38'
                x2='34.4'
                y2='92.2'
              />
            </svg>
          </>
        )}
        {status === 'Redeemed Invite' && (
          <svg
            className='checkmark_green'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 52 52'
          >
            <circle
              className='checkmark__circle'
              cx='26'
              cy='26'
              r='25'
              fill='none'
              stroke='#7ac142'
            />
            <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
          </svg>
        )}
      </div>
      {status.msg === 'Valid Invite' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Paper sx={{ padding: '10px' }}>
            <Typography
              style={{
                paddingBottom: '2px',
              }}
              variant='h6'
            >
              {`You have been invited to ${status.entity}`}
            </Typography>
            <Button
              onClick={() => {
                redeemMutation.mutate({
                  body: { code: code },
                });
              }}
              style={{
                backgroundColor: '#FF9900',
                color: '#FFFFFF',
              }}
            >
              ACCEPT
            </Button>
            {!status.emailMatch && (
              <>
                <Typography
                  style={{
                    paddingTop: '2px',
                  }}
                >
                  After you accept the invite,
                </Typography>
                <Typography>You will still need to be approved.</Typography>
              </>
            )}
          </Paper>
        </Box>
      ) : (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '100px',
          }}
        >
          {status}
        </Typography>
      )}
    </>
  );
};

export default InviteProcess;

import { useEffect, useState } from 'react';
// LIBRARIES
// import { API } from 'aws-amplify'; // Auth
// import { useQuery, useQueryClient } from '@tanstack/react-query';
// import { useNavigate } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
// import { useAuthenticator } from "@aws-amplify/ui-react";

// MATERIAL
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

// COMPONENTS
import SearchBar from '../components/SearchBar';
import DoorCard from '../components/DoorCard';
import { PermissionError } from '../components/PermissionError';

import { useDevicesQuery } from '../hooks/useDeviceQuery';

// FUNCTIONS
// import { getUsersInvitesApi } from '../functions/invite'; //ApplyInvite, CheckInvite,
// import { useDevices } from '../functions/useDevices';
// import useSearch from '../functions/useSearch';
// import { useLocationContext } from '../components/LocationContext';
import { usePosition } from '../hooks/usePosition';
// import { detectDistance } from '../functions/detectDistance';

export default function Dashboard(props) {
  // const { invite } = props;
  const { latitude, longitude, positionError } = usePosition();

  const { devices, isLoading, isFetching, error, handleDeviceFilters, deviceFilter } =
    useDevicesQuery({ lat: latitude, lng: longitude });

  // ****************************************************** INVITE STUFF ******************************************************
  // Queries
  // const { data: inviteData, status: inviteStatus } = useQuery({
  //   queryKey: ['invites'],
  //   queryFn: () => getUsersInvitesApi(),
  // });
  // console.log('INVITE DATA', inviteData, inviteStatus);

  // useEffect(() => {
  //   // console.log('Hello',invite , inviteData , inviteStatus);
  //   if (inviteData?.length > 0 && inviteStatus === 'success') {
  //     console.log('Dashboard Invite', inviteData);
  //     inviteData.forEach((invite) => {
  //       enqueueSnackbar(`You have been invited to ${invite.entity}.`, {
  //         variant: 'success',
  //       });
  //       queryClient.invalidateQueries(['devices']);
  //     });
  //   }
  // }, [enqueueSnackbar, inviteData, inviteStatus, queryClient]);

  const [searchText, setSearchText] = useState('');
  const handleSearchText = (text) => {
    setSearchText(text);
  };

  return (
    <div>
      {positionError ? (
        <PermissionError serviceName='location' />
      ) : (
        <>
          <SearchBar
            searchText={searchText}
            handleText={handleSearchText}
            onRefresh={() => setSearchText('')}
            handleFilters={handleDeviceFilters}
            filters={deviceFilter}
          />
          {isLoading === 'loading' && (
            <LinearProgress color='primary' sx={{ marginBottom: 2 }} />
          )}
          <Grid container spacing={1}>
            {devices &&
              devices.length > 0 &&
              devices
                .filter((row) => {
                  return row.deviceName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                })
                .map((device, index) => (
                  <Grid xs={6} md={6} item key={index} style={{ display: 'flex' }}>
                    <DoorCard device={device} />
                  </Grid>
                ))}
          </Grid>
        </>
      )}
    </div>
  );
}

import { useState, useEffect } from 'react';

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

export const usePosition = (watch = false, userSettings = {}) => {
  const settings = {
    ...defaultSettings,
    ...userSettings,
  };

    // iOS doesn't have navigator.permissions.query, so we skip it if we failed to get the user's location
  // const requestLocation = async () => {
  //   if (!/(safari)/i.test(navigator.userAgent)) {
  //     const permission = await navigator.permissions.query({ name: 'geolocation' });
  //   }
  // }

  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = ({ coords, timestamp }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp,
    });
  };

  const onError = (error) => {
    console.log('LOCATION ERROR', error);
    setError(error);
  };



  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported');
      return;
    }

    if (watch) {
      const watcher = navigator.geolocation.watchPosition(onChange, onError, settings);
      return () => navigator.geolocation.clearWatch(watcher);
    }

    navigator.geolocation.getCurrentPosition(onChange, onError, settings);
  }, [settings.enableHighAccuracy, settings.timeout, settings.maximumAge, watch]);

  return { ...position, positionError: error };
};

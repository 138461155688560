//@format
import {ApplyInvite} from './invite';
import {API} from 'aws-amplify';
import * as Sentry from '@sentry/react';

export const listener = async data => {
    // console.log('LISTENER ', data)
    switch (data?.payload?.event) {
        case 'customOAuthState':
            //console.log('CUSTOM DATA', data);
            const invite = data.payload.data;
            if (invite) await ApplyInvite(invite);
            break;
        case 'signOut':
            Sentry.setUser(null);
            window.location.replace('/');
            //console.log('Goodbye :-)');
            break;
        case 'signIn':
            Sentry.setUser({username: data.payload.data.username});
            await API.post('Scanseqr', 'authentication/attach-policy').then(async result => {
                //console.log('Added IOT Policy', result);
            });
            break;
        default:
            console.log('“Deeds will not be less valiant because they are unpraised.” — Aragorn');
            break;
    }
};

import { Box, Typography, Paper } from '@mui/material';

export const PermissionError = ({ serviceName }) => {
  // Check the width of the users window

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
      <Paper sx={{ padding: '20px' }}>
        <Typography
          style={{
            paddingBottom: '2px',
            textAlign: 'center',
          }}
          variant='h6'
          color='error'
        >
          {`ScanseQR requires access to your ${serviceName}.`}
        </Typography>
        <Typography
          style={{
            paddingBottom: '2px',
            textAlign: 'center',
          }}
          variant='caption'
        >
          {`Please enable ${serviceName} services in your browser settings,`}
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
          }}
          variant='caption'
        >
          then refresh the page.
        </Typography>
      </Paper>
    </Box>
  );
};

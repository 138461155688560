import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: { main: '#ff9900' },
        secondary: {main: '#1f2833' },
        error: { main: '#f44236' },
        info: { main: '#ffffff' },
    },
    typography: {
        fontFamily: [
            'Courier New'
        ].join(','),
    },
});

export default theme
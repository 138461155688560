import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';

// HOOKS
import { usePosition } from '../hooks/usePosition';

// COMPONENTS
import { PermissionError } from '../components/PermissionError';

// CSS
import TriggerProcess from '../components/TriggerProcess';


const Trigger = (props) => {
  const { method } = props;
  const loc = useLocation().pathname.split('/'); // This is the url path!
  const [p] = useSearchParams();
  const deviceId = parseInt(p.get('q')) || null;
  const deviceUuid = loc[2];
  const command = loc[1];

  const { user } = useAuthenticator();
  const [identityId, setIdentityId] = useState(null);

  useEffect(() => {
    const getIdentityId = async () => {
      const identityId = await Auth.currentCredentials().then((info) => info.identityId);
      console.log('identityId: ', identityId);
      setIdentityId(identityId);
    };
    // Disable the trigger if the user is not viewing the page (ie. chromes prerender) or is not authenticated
    if (document.visibilityState === 'visible' && identityId === null && user?.signInUserSession?.accessToken !== undefined) {
      getIdentityId();
    }
  }, [user.signInUserSession.accessToken, identityId]);

  const { latitude, longitude, positionError } = usePosition();

  console.log(
    'TRender: ',
    identityId,
    latitude,
    longitude,
    method,
    deviceId,
    deviceUuid,
    command
  );

  return (
    <>
      {positionError && <PermissionError serviceName='location' />}
      {identityId &&
        latitude &&
        longitude &&
        method &&
        (deviceId || deviceUuid) &&
        command && (
          <TriggerProcess
            identityId={identityId}
            latitude={latitude}
            longitude={longitude}
            method={method}
            deviceId={deviceId}
            deviceUuid={deviceUuid}
            command={command}
          />
        )}
    </>
  );
};

export default Trigger;

import { useEffect } from 'react';
import { PubSub } from 'aws-amplify';

const subscribeToTopic = (topic, callback) => {
  console.log(`MQTT - Subscribing to: ${topic}`);
  const subscription = PubSub.subscribe(topic).subscribe({
    next: (data) => callback(data),
    error: (error) => console.error('SUB ERR', error),
    complete: () => console.log('Done'),
  });
  return subscription;
};

const useMQTT = ( topic, callback ) => {
    useEffect(() => {
        const newSubscription = subscribeToTopic(topic, (data) =>
            callback(data)
        );
  
      return () => {
          console.log(`MQTT - Unsubscribing from: ${topic}`);
          newSubscription.unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topic]);
};

export default useMQTT;